import firebaseconfig from './firebaseIndex'
import firebase from 'firebase'

let authListener = null

const authChangeListener = () => {
    return firebase.auth().onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
            localStorage.setItem('servistorUser', JSON.stringify(firebaseUser));
            console.log('logged in', firebaseUser)
        } else {
            console.log('not logged in')
            localStorage.removeItem('servistorUser');
        }
    })
}

export const authMethods = {

    //  signUp: async (email, password) => {
    //     console.log("", email, password)
    //     await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
    //         return firebase.auth().createUserWithEmailAndPassword(email, password)
    //             .then(res => {
    //                 console.log(res)
    //             })
    //             .catch(err => {
    //                 console.error(err)
    //             })
    //     }).catch(err => {
    //         console.error(err)
    //     })
    // },

    signIn: async (email, password) => {
        // console.log("sign in", email, password)
        return new Promise((resolve, reject) => {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                    firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
                        if (authListener == null) {
                            console.log("register listener")
                            authListener = authChangeListener();
                        }
                        resolve(user)
                    }).catch(err => {
                        console.error(err)
                        reject(err)
                    })

                }).catch(err => {
                    console.error(err)
                    reject(err)
                })
        });


    },

    signOut: () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut()
                .then(() => {
                    console.log("signOut",)
                    resolve()
                })
                .catch(err => {
                    console.error(err)
                    reject(err)
                })
        })
    },
}