import React from "react";

import { useTranslation } from "react-i18next";
import { Parallax } from "react-parallax";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Restaurant from "@material-ui/icons/Restaurant";

import PriceList from "./priceList.jsx";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black",
  },
  subtitle: {
    padding: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black",
  },
  button: {
    textAlign: "center",
    color: "white",
    textShadow: "1px 1px 1px black",
  },
  icon: {
    fontSize: 70,
    color: "white",
    paddingBottom: theme.spacing(2),
  },
}));

const CateringInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [togglePrices, setTogglePrices] = React.useState(false);
  const showPrices = () => {
    setTogglePrices(!togglePrices);
  };
  return (
    <div
      id="info"
      style={{
        background: "linear-gradient(#283048, #859398)",
      }}
    >
      <Parallax
        style={{ textAlign: "center" }}
        bgImage={require("../assets/img/collage3.jpg")}
        bgImageStyle={{ opacity: 0.4 }}
        strength={300}
      >
        <Typography className={classes.title} variant="h3" noWrap>
          {t("catering.title")}
        </Typography>
        <Typography className={classes.subtitle} variant="h5">
          {t("catering.subtitle")}
        </Typography>
        <Typography className={classes.subtitle} variant="h5">
          {t("catering.where")}
        </Typography>
        <Restaurant className={classes.icon} />
        <div id="prices" style={{ display: "block", textAlign: "center" }}>
          {togglePrices ? (
            <PriceList />
          ) : (
            <React.Fragment>
              <Typography variant="h6" className={classes.subtitle}>
                {t("catering.prices")}
              </Typography>
            </React.Fragment>
          )}
          <Button
            onClick={showPrices}
            className={classes.button}
            variant="outlined"
          >
            {togglePrices
              ? t("catering.pricesButton.show")
              : t("catering.pricesButton.hide")}
          </Button>
        </div>
        <div style={{ height: "200px" }}></div>
      </Parallax>
    </div>
  );
};

export default CateringInfo;
