import React from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import CalendarBooked from "./calendarBooked";
import CalendarBook from "./calendarBook";
import SwipeableImages from "./swipeableImages";
import { useDispatch, useSelector } from "react-redux";
import { setCaravanData } from "../actions";

import { storeMethods } from "../firebase/firestore";
import moment from "moment";

import SendEmail from "./sendEmail";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    minWidth: "332px",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  dialogMedia: {
    paddingTop: "56.25%", // 16:9
  },
}));

function CaravanCard(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const caravanData = useSelector((state) => {
    return state.caravanData;
  });

  const isAuthenticated = useSelector((state) => {
    return state.isAuthenticated;
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [showDates, setShowDates] = React.useState(false);
  // const [showBook, setShowBook] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleBookDate = (date) => {
    const momentDate = moment(date);
    console.log(" date", momentDate);

    const queryData = () => {
      storeMethods
        .getAllBookings()
        .then((res) => {
          dispatch(setCaravanData(res));
        })
        .catch((err) => console.log(err));
    };
    if (
      caravanData
        .filter((i) => i.caravanId === props.caravan.id)
        .some((j) => j.date.isSame(momentDate, "day"))
    ) {
      console.log("delete date", date);
      storeMethods
        .deleteBooking(props.caravan.id, date)
        .then(() => queryData())
        .catch((err) => console.log(err));
    } else {
      storeMethods
        .putBooking(props.caravan.id, date)
        .then(() => queryData())
        .catch((err) => console.log(err));
    }
  };

  const [isEmailModalOpen, setEmailModalOpen] = React.useState(false);
  const closeEmailModal = () => {
    setEmailModalOpen(false);
  };
  const [bookMessage, setBookMessage] = React.useState("");
  const handleMessageChange =(event) => {
    setBookMessage(event.target.value);
  };

  const handleBook = () => {
    if (!props.dateRange) {
      setTooltipOpen(true);
      return;
    }
    setTooltipOpen(false);
    console.log("handlbook", props.caravan.id);
    setBookMessage(
      ` Soovin rentida ${props.caravan.title} ${props.dateRange.startDate.toLocaleDateString("et-ET", {
        month: "long",
        day: "numeric",
      })} - ${props.dateRange.endDate.toLocaleDateString("et-ET", {
        month: "long",
        day: "numeric",
      })}`
    );
    setEmailModalOpen(true);
  };

  const getEstimatedPrice = () => {
    if (!props.dateRange) return "";
    const start = moment(props.dateRange.startDate);
    const end = moment(props.dateRange.endDate);
    const diff = Math.abs(end.diff(start, "days") + 1); // include start;
   
    return `~${diff * props.caravan.pricePerDay}€`;
  };

  return (
    <div>
      <SendEmail
        isEmailModalOpen={isEmailModalOpen}
        closeEmailModal={closeEmailModal}
        message={bookMessage}
        handleMessageChange={handleMessageChange}
      />
      <Card className={classes.card}>
        {/* <pre>{JSON.stringify(caravanData)}</pre> */}
        {/* <pre>{JSON.stringify(props.dates)}</pre> */}
        {/* { <pre>{JSON.stringify(props)}</pre>} */}

        <SwipeableImages
          caravan={props.caravan}
          openDialog={handleClickOpenDialog}
          mediaClass={classes.cardMedia}
        />

        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.caravan.title}
          </Typography>
          <Typography>Price per day {props.caravan.pricePerDay} €</Typography>
          <br />
          <Typography>{props.caravan.description}</Typography>
        </CardContent>
        {showDates && (
          <div>
            <CalendarBooked disabledDates={props.dates} />
            {isAuthenticated && (
              <CalendarBook
                bookDate={handleBookDate}
              />
            )}
          </div>
        )}

        <CardActions>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                placement={"top"}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Select dates first"
              >
                <Button size="small" color="primary" onClick={handleBook}>
                  Book {getEstimatedPrice()}
                </Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
          <Button
            size="small"
            color="primary"
            onClick={() => setShowDates(!showDates)}
          >
            {showDates ? "Hide dates" : "Show dates"}
          </Button>
        </CardActions>
      </Card>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogActions>
          <IconButton onClick={handleCloseDialog} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogActions>

        <SwipeableImages
          caravan={props.caravan}
          mediaClass={classes.dialogMedia}
        />
      </Dialog>
    </div>
  );
}
export default CaravanCard;
