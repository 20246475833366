import React from "react";

import moment from "moment";
import "moment/locale/et";

import { makeStyles } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DateRangePicker from "./dateRangePicker";
import Modal from "@material-ui/core/Modal";

import cards from "./../assets/data/caravans.json";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux"; // useDispatch,

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  modal: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function RentToolbar(props) {
  const rentDates = useSelector((state) => {
    return state.caravanData;
  });
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleRangeChange = (payload) => {
    const keys = Object.keys(payload);
    console.log(payload);
    if (payload[keys[0]].startDate && payload[keys[0]].endDate)
      props.setDateRange(payload[keys[0]]);
  };

  const handleTextFieldPress = () => {
    props.setDateRange({
      startDate: new Date(),
      endDate: new Date(),
    });
    setIsModalOpen(true);
  };

  const handleDateRangeInputClear = (e) => {
    props.nullDateRange();

    e.stopPropagation();
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const getDatesWhereFullyBooked = () => {
    const uniqueCaravans = cards
      .filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )
      .map((e) => e.id);

    let disabledDates = [];
    let dateMap = new Map();
    rentDates.forEach((e) => {
      const timestamp = e.date.unix();
      if (
        dateMap.has(timestamp) &&
        dateMap[timestamp].indexOf(e.caravanId) === -1
      ) {
        dateMap[timestamp].push(e.caravanId);
      } else {
        dateMap.set(timestamp);
        dateMap[timestamp] = [e.caravanId];
      }

      if (
        dateMap[timestamp].length === uniqueCaravans.length &&
        disabledDates.indexOf(timestamp) === -1
      ) {
        disabledDates.push(new Date(timestamp * 1000));
      }
    });
    // console.log("dateMap", dateMap, "disabledDates", disabledDates);
    return disabledDates;
  };
  const { t } = useTranslation();

  const classes = useStyles();
  var fullyBookedDates = getDatesWhereFullyBooked();

  return (
    <div>
      <Toolbar>
        <TextField
          className={classes.textField}
          onClick={() => handleTextFieldPress()}
          label={t("rentDate.title")}
          placeholder={
            props.dateRange
              ? `${moment(props.dateRange.startDate).format("LL")} - ${moment(
                  props.dateRange.endDate
                ).format("LL")}`
              : t("rentDate.placeHolder")
          }
          helperText={props.dateRange ? t("rentDate.placeHolder") : ""}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon />
              </InputAdornment>
            ),
            endAdornment: props.dateRange && (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => handleDateRangeInputClear(e)}
                  color="primary"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <pre> {JSON.stringify(props.dateRange)}</pre> */}
      </Toolbar>
      <Modal
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isModalOpen}
        onClose={handleClose}
      >
        <div>
          <DateRangePicker
            className={classes.modal}
            dateRange={props.dateRange}
            disabledDates={fullyBookedDates}
            handleRangeChange={handleRangeChange}
          />
        </div>
      </Modal>
    </div>
  );
}

// RentDate.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// RentDate = withStyles(styles)(RentDate);
// export default withTranslation()(RentDate);
