import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import priceData from "../assets/data/cateringPrices.json";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    color: "white",
    textShadow: "1px 1px 1px black"
  },
  subtitle: {
    textAlign: "center",
    display: "flex",
    color: "white",
    textShadow: "1px 1px 1px black",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const PriceList = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.subtitle}>
        Lauanõude ja tarvikute rent.
      </Typography>
      <Container>
        <Grid container justify="center" spacing={4}>
          {priceData.map((data,i) => (
            <Grid key={i} style={{ textAlign: "center" }} item lg={4} md={3} sm={2}>
              <Typography variant="h5" className={classes.subtitle}>
                {data.title}
              </Typography>
              <Typography variant="h6" className={classes.subtitle}>
                <ul>
                  {data.items.map((item, i) => (
                    <li key={i}>
                      {item.name} {item.price} €
                    </li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Typography variant="h6" className={classes.subtitle}>
        Hindadele lisandub käibemaks.
      </Typography>
    </div>
  );
};
export default PriceList;
