import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { authMethods } from "../firebase/firebaseAuth";
import { setIsAuthenticated } from "../actions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Login = (props) => {
  //   const { email, password } = props;

  const isAuthenticated = useSelector((state) => {
    return state.isAuthenticated;
  });

  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = React.useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const [loginOpen, setLoginOpen] = React.useState(false);

  const handleClickLoginOpen = () => {
    setLoginOpen(true);
  };
  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const dispatch = useDispatch();

  const login = () => {
    authMethods
      .signIn(email, password)
      .then((res) => {
        console.log(res);
        //lastSignInTime: res.user.metadata.lastSignInTime
        dispatch(setIsAuthenticated(res.user.email));
        setLoginOpen(false);
      })
      .catch((err) => {
        dispatch(setIsAuthenticated(null));
        var errorCode = err.code;
        var errorMessage = err.message;
        if (errorCode === "auth/wrong-password") {
          alert("Wrong password.");
        } else {
          alert(errorMessage);
        }
        console.log(err);
        setLoginOpen(false);
      });
  };
  const logout = () => {
    authMethods
      .signOut()
      .catch((err) => {
        var errorMessage = err.message;
        console.error(errorMessage);
      })
      .finally(() => dispatch(setIsAuthenticated(null)));
  };

  return (
    <div>
      {isAuthenticated ? (
        <Button
          endIcon={<ExitToAppIcon />}
          onClick={() => logout()}
          color="primary"
        >
          {isAuthenticated} logout
        </Button>
      ) : (
        <Button
          endIcon={<ExitToAppIcon />}
          onClick={() => handleClickLoginOpen()}
          color="primary"
        >
          Admin login
        </Button>
      )}

      <Dialog
        open={loginOpen}
        onClose={handleLoginClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            onChange={handleEmailChange}
            value={email}
          />
          <TextField
            fullWidth
            margin="dense"
            id="password"
            label="Password"
            type="password"
            onChange={handlePasswordChange}
            value={password}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => login()} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Login;
