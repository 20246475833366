import React from "react"; //, { useEffect }
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import cards from "./../assets/data/caravans.json";

import CaravanCard from "../components/caravanCard";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

function Caravans(props) {
  const rentDates = useSelector((state) => {
    return state.caravanData;
  });

  const classes = useStyles();
  const getDates = (c) => {
    const res =
      rentDates.filter((i) => i.caravanId === c.id).map((j) => j.date) || [];

    return res;
  };
  const isCaravanFree = (c) => {
    const res = getDates(c);
    if (props.dateRange) {
      return !res.some((d) => {
        const date = d;
        const start = moment(props.dateRange.startDate);
        const end = moment(props.dateRange.endDate);
        const res = date.isSameOrAfter(start) && date.isSameOrBefore(end);

        return res;
      });
    }
    return true;
  };

  return (
    <Container className={classes.cardGrid}>
      {/* <pre>{JSON.stringify(props)}</pre> */}

      <Grid container spacing={4}>
        {cards.map(
          (c) =>
            isCaravanFree(c) && (
              <Grid item key={c.id} xs={12} sm={12} md={6} lg={4} xl={4}>
                <CaravanCard
                  caravan={c}
                  dates={getDates(c)}
                  dateRange={props.dateRange}
                />
              </Grid>
            )
        )}
      </Grid>
    </Container>
  );
}
export default Caravans;
