export const setIsAuthenticated = (val) => {
    console.log('action setIsAuthenticated', val)

    return {
        type: "SET_IS_AUTHENTICATED",
        payload: val
    }
}

export const setCaravanData = (val) => {
    console.log('action setCaravanData', val)

    return {
        type: "SET_CARAVAN_DATA",
        payload: val
    }
}

export const add = (value) => {
    return {
        type: "ADD",
        payload: value
    }
}

export const remove = (value) => {
    return {
        type: "REMOVE",
        payload: value
    }
} 