import isAuthenticatedReducer from './auth';
import caravanDataReducer from './caravans';
import counterReducer from './counter';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    isAuthenticated: isAuthenticatedReducer,
    caravanData: caravanDataReducer,
    counter: counterReducer,
})

export default rootReducer;