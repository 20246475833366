import React from "react";

import { useTranslation } from "react-i18next";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from "@material-ui/core/Typography";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";

import "./styles/carousel.css";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import useWindowDimensions from "./useWindowDimensions";

const images = [
  "image00001.JPG",
  "image00002.JPG",
  "image00003.JPG",
  "image00004.JPG",
  "image00005.JPG",
  "image00006.JPG",
  "image00007.JPG",
  "image00008.JPG",
  "image00009.JPG",
  "image00010.JPG",
  "image00011.JPG",
  "image00012.JPG",
  "image00013.JPG",
  "image00014.JPG",
  "image00015.JPG"
];
const useStyles = makeStyles(theme => ({
  carousel: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black"
  },
  icon: {
    fontSize: 70,
    color: "white",
    paddingBottom: theme.spacing(2)
  }
}));

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

function dynamicWidth(width, height) {
  if (width / height < 3 / 4) {
    return "100%";
  }
  if (width / height > 2 / 1) {
    return "25%";
  }
  const ar = calculateAspectRatioFit(3024, 4032, width, height);
  return `${((ar.width * 0.8) / width) * 100}%`;
}

const Pictures = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  return (
    <div id="pictures">
      <Typography className={classes.title} variant="h3">
        <PhotoLibraryOutlinedIcon className={classes.icon} />
        {t("pictures.title")}
      </Typography>
      <Carousel
        width={dynamicWidth(width, height)}
        className={classes.carousel}
        showArrows={false}
        emulateTouch={true}
        showThumbs={isMobile ? false : true}
      >
        {images.map(image => (
          <div
            key={image}
            style={{
              padding: 10
            }}
          >
            <img
              key={image}
              src={require(`../assets/img/catering2/${image}`)}
              alt={image}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default Pictures;
