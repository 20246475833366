import React, { Component } from "react";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "moment/locale/et";

import { et } from "date-fns/locale";

class DateRangePicker extends Component {
  render() {
    return (
      <DateRange
        locale={et}
        minDate={new Date()}
        ranges={[this.props.dateRange]}
        disabledDates={this.props.disabledDates}
        onChange={this.props.handleRangeChange}
      />
    );
  }
}

export default DateRangePicker;
