import React from "react";

import HomeMain from "./homeMain";
import HomeCatering from "./homeCatering";
import HomeRent from "./homeRent";


const Home = () => {
  return (
    <React.Fragment>
      <HomeMain/>
      <HomeCatering/>
      <HomeRent/>
    </React.Fragment>
  );
};

export default Home;
