import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FacebookIcon from "@material-ui/icons/Facebook";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import Restaurant from "@material-ui/icons/Restaurant";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";

import { useTranslation } from "react-i18next";

const SideMenu = ({ state, side, toggleDrawer }) => {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer
      open={state.left}
      onClose={toggleDrawer("left", false)}
      onOpen={toggleDrawer("left", true)}
    >
      <div
        style={{
          width: 250,
        }}
        role="presentation"
        onClick={toggleDrawer(side, false)}
        onKeyDown={toggleDrawer(side, false)}
      >
        <List>
          <Link to="/" smooth>
            <ListItem>
              <img
                src={require("../assets/icon/logo.jpg")}
                alt=""
                width="220"
              ></img>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link to="/rent#info" smooth>
            <ListItem>
              <ListItemIcon>
                <DateRangeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("sideMenu.rentInfo")} />
            </ListItem>
          </Link>

          <Link to="/rent#dates" smooth>
            <ListItem>
              <ListItemIcon>
                <DateRangeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("sideMenu.freeDates")} />
            </ListItem>
          </Link>

          <Link to="/rent#contact" smooth>
            <ListItem>
              <ListItemIcon>
                <ContactPhoneOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("contact.contact")} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link to="/catering#info" smooth>
            <ListItem>
              <ListItemIcon>
                <Restaurant />
              </ListItemIcon>
              <ListItemText primary={t("sideMenu.cateringInfo")} />
            </ListItem>
          </Link>

          <Link to="/catering#prices" smooth>
            <ListItem>
              <ListItemIcon>
                <Restaurant />
              </ListItemIcon>
              <ListItemText primary={t("sideMenu.menu")} />
            </ListItem>
          </Link>

          <Link to="/catering#pictures" smooth>
            <ListItem>
              <ListItemIcon>
                <Restaurant />
              </ListItemIcon>
              <ListItemText primary={t("sideMenu.pictures")} />
            </ListItem>
          </Link>

          <Link to="/catering#contact" smooth>
            <ListItem>
              <ListItemIcon>
                <ContactPhoneOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("contact.contact")} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <a
            display="block"
            variant="body1"
            href="https://www.facebook.com/servistorcatering/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem>
              <ListItemIcon>
                <FacebookIcon />
              </ListItemIcon>
              <ListItemText primary="Servistor catering" />
            </ListItem>
          </a>
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default SideMenu;
