import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import emailjs from "emailjs-com";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 345,
  },
  avatar: {
    backgroundColor: "red",
  },
  modal: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function SendEmail(props) {

  const sendEmail = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        "gmail",
        "userquestion",
        event.target,
        "Pa2eQU4XliNHuBQDV"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          alert("Something went wrong, please contact us directly via email in contact section")
          console.log(error.text);
        }
      );
    props.closeEmailModal();
  };


  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Modal
      className={classes.modal}
      open={props.isEmailModalOpen}
      onClose={() => props.closeEmailModal()}
    >
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              S
            </Avatar>
          }
          title={t("email.title")}
          subheader={t("email.subtitle")}
        />
        <form onSubmit={sendEmail}>
          <CardContent>
            <input type="hidden" name="contact_number" />
            <TextField
              fullWidth
              label={t("email.telephoneNr")}
              inputProps={{ type: "tel", name: "senderTelephoneNr" }}
             
            />
            <TextField
              fullWidth
              label={t("email.email")}
              inputProps={{ type: "email", name: "senderEmail" }}
            />

            <TextField
              fullWidth
              label={t("email.message")}
              multiline
              rows="4"
              placeholder={t("email.messagePlaceholder")}
              inputProps={{ name: "message" }}
              onChange={props.handleMessageChange}
              value={props.message}
            />
          </CardContent>

          <CardActions disableSpacing>
            <Button
              type="submit"
              value="Send"
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<SendIcon>send</SendIcon>}
            >
              {t("email.submit")}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
