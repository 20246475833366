import React, { Component } from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
// import tileData from './tileData';

import ImageModal from "./imageModal";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    maxWidth: "720px"
  }
});

const images = [
  "image00001.JPG",
  "image00002.JPG",
  "image00003.JPG",
  "image00010.JPG",
  "image00004.JPG",
  "image00005.JPG",
  "image00006.JPG",
  "image00007.JPG",
  "image00008.JPG",
  "image00009.JPG",
  "image00011.JPG",
  "image00012.JPG"
];

const tileData = [
  {
    img: images[0],
    cols: 1,
    rows: 1
  },
  {
    img: images[1],
    cols: 1,
    rows: 1
  },
  {
    img: images[2],
    cols: 1,
    rows: 1
  },
  {
    img: images[3],
    cols: 3,
    rows: 3
  },
  {
    img: images[4],
    cols: 1,
    rows: 1
  },
  {
    img: images[6],
    cols: 1,
    rows: 1
  },
  {
    img: images[7],
    cols: 1,
    rows: 1
  },
  {
    img: images[8],
    cols: 1,
    rows: 1
  },
  {
    img: images[9],
    cols: 2,
    rows: 1
  },
  {
    img: images[10],
    cols: 1.5,
    rows: 1
  },
  {
    img: images[11],
    cols: 1.5,
    rows: 1
  }
];

class ImageGridList extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  getImageSrcAndToggle(e) {
    this.child.current.toggle(e.currentTarget.alt)
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <GridList
          cellHeight={200}
          className={classes.gridList}
          rows={3}
          cols={3}
        >
          {tileData.map(tile => (
          
            <GridListTile

              key={tile.img}
              cols={tile.cols}
              rows={tile.rows}
            >
              <img
                key={tile.img}
                onClick={this.getImageSrcAndToggle.bind(this)}
                src={require(`../assets/img/catering1/thumb/${tile.img}`)}
                alt={tile.img}
              />
            </GridListTile >
          ))}
          <ImageModal ref={this.child}/>

        </GridList>
      </div>
    );
  }
}

ImageGridList.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ImageGridList);

