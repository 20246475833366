import React from "react";
// import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import Iframe from "react-iframe";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  footer: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  text :{
    color: "#333",
  },
}));

const Footer = ({ Tel, Address, Email, Juridical, Location }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer id="contact" className={classes.footer}>
      <Container>
        <Grid container spacing={8} justify="center">
          <Grid item style={{ align: "left" }}>
            <Typography variant="h6" align="center" gutterBottom className={classes.text}>
              {t("contact.contact") + " " + Juridical}
            </Typography>

            <Typography
              className={classes.text}
              variant="subtitle1"
              align="left"
              color="textSecondary"
              component="address"
            >
              <LocationOnOutlinedIcon />
              {Address}
            </Typography>

            <Typography
              className={classes.text}
              variant="subtitle1"
              align="left"
              color="textSecondary"
              component="a"
              href={"tel:" +  Tel }
            >
              <CallOutlinedIcon />
              {Tel}
            </Typography>
            <br />
            <Typography
              className={classes.text}
              variant="subtitle1"
              align="left"
              color="textSecondary"
              component="a"
              href={`mailto:${Email}`}
            >
              {Email}
            </Typography>
          </Grid>
          <Grid item>
            <Iframe
              url={Location}
              width="350"
              height="300"
              // id="myMap"
              // className="myMapClassName"
              display="initial"
              position="relative"
            />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
export default Footer;
