import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Parallax } from "react-parallax";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { HashLink as Link } from "react-router-hash-link";

import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import Footer from "./footer";

import ContactUs from "./contactUs";

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black",
  },
  subtitle: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black",
  },
  grid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  gridItem: {
    textAlign: "center",
  },
  button: {
    color: "white",
    textShadow: "1px 1px 1px black",
    textAlign: "center",
  },
  icon: {
    fontSize: 70,
    color: "white",
    paddingBottom: theme.spacing(2),
  },
  modal: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class HomeRent extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <div id="rent">
         
        <Parallax bgImage={require("../assets/img/rent-bg.jpg")} strength={500}>
        <svg
          width="100%"
          height="100"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path d="M0 0 L0 90 L100 0 V0 H0" fill="whitesmoke" />
        </svg>
          <Typography className={classes.title} variant="h3">
            {t("landing.rent.title")}
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            {t("landing.rent.subtitle")}
          </Typography>
          <Grid container justify="center" spacing={2}>
            <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
              <InfoOutlined className={classes.icon} />
              <Typography className={classes.subtitle} variant="h6" paragraph>
                {t("landing.rent.info")}
              </Typography>
              <Link to="rent/#info" smooth>
                <Button type="button" className={classes.button}>
                  {t("landing.readMore")}
                </Button>
              </Link>
            </Grid>
            <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
              <DateRangeOutlinedIcon className={classes.icon} />
              <Typography className={classes.subtitle} variant="h6" paragraph>
                {t("landing.rent.freeDates")}
              </Typography>
              <Link to="/rent#dates" smooth>
                <Button type="button" className={classes.button}>
                  {t("landing.readMore")}
                </Button>
              </Link>
            </Grid>
            <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
              <Button
                className={classes.button}
                onClick={() => this.child.current.handleOpen()}
              >
                {t("contact.contactUs")}
              </Button>
              <ContactUs ref={this.child} />
            </Grid>
          </Grid>

          
          <svg
          width="100%"
          height="100"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path d="M0 0 L50 90 L100 0 V100 H0" fill="white" />
        </svg>
        </Parallax>
        <Footer
            Tel="+372 515 3975"
            Email="hallrauno@gmail.com"
            Address="Rapla, 79513 Rapla maakond"
            Juridical="Servistor Rent OÜ (14737939)"
            Location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16439.481936650674!2d24.792016006085536!3d58.999549413053536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692d3b40ad4659d%3A0x83469c3b526bf380!2sRapla%2C%20Rapla%20County!5e0!3m2!1sen!2see!4v1582569095841!5m2!1sen!2see"
          />
      </div>
    );
  }
}

HomeRent.propTypes = {
  classes: PropTypes.object.isRequired,
};
HomeRent = withStyles(styles)(HomeRent);
export default withTranslation()(HomeRent);
