import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";


import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { HashLink as Link } from "react-router-hash-link";

import RestaurantOutlinedIcon from "@material-ui/icons/RestaurantOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";

import ContactUs from "./contactUs";

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),

  },

  homeImg: {
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  subtitle: {
    padding: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "#333333",
  },
  grid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  gridItem: {
    textAlign: "center",
  },
  button: {
    color: "#333333",
  },
  icon: {
    fontSize: 70,
    color: "grey",
    paddingBottom: theme.spacing(2),
  },
});

class HomeMain extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <div id="firstTag">
        <Grid container direction="row" justify="center" align="center">
          <Grid item xs={11}>
            {/* <Card className={classes.card}> */}
              <img
                className={classes.homeImg}
                src={require(`../assets/icon/logo_160.jpg`)}
                alt=""
              ></img>
              {/* <Typography className={classes.title} variant="h3">
              {t("landing.main.title")}
            </Typography>
            <Typography className={classes.subtitle} variant="h5">
              {t("landing.main.subtitle")}
            </Typography> */}

              <Grid container direction="row" justify="center">
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <RestaurantOutlinedIcon className={classes.icon} />
                  <Typography
                    className={classes.subtitle}
                    variant="h6"
                    paragraph
                  >
                    {t("landing.main.cateringInfo")}
                  </Typography>
                  <Link to="/#catering" smooth>
                    <Button type="button" className={classes.button}>
                      {t("landing.readMore")}
                    </Button>
                  </Link>
                </Grid>

                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <DateRangeOutlinedIcon className={classes.icon} />
                  <Typography
                    className={classes.subtitle}
                    variant="h6"
                    paragraph
                  >
                    {t("landing.main.rentInfo")}
                  </Typography>
                  <Link to="/#rent" smooth>
                    <Button type="button" className={classes.button}>
                      {t("landing.readMore")}
                    </Button>
                  </Link>
                </Grid>

                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Grid container direction="row" justify="center">
                    <Link
                      to={{ pathname: "https://www.facebook.com/servistorcatering/" }}
                      target="_blank"
                    >
                      <FacebookIcon />
                      Servistor catering
                    </Link>
                  </Grid>
                  <Button
                    className={classes.button}
                    onClick={() => this.child.current.handleOpen()}
                  >
                    {t("contact.contactUs")}
                  </Button>
                  <ContactUs ref={this.child} />
                </Grid>
              </Grid>
            {/* </Card> */}
          </Grid>
          {/* <Grid item>
            <img
              src={require(`../assets/img/home1.jpeg`)}
              alt=""
            ></img>
          </Grid> */}
        </Grid>
        <svg
          width="100%"
          height="100"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path d="M0 0 L50 90 L100 0 V100 H0" fill="whitesmoke" />
        </svg>
      </div>
    );
  }
}

HomeMain.propTypes = {
  classes: PropTypes.object.isRequired,
};
HomeMain = withStyles(styles)(HomeMain);
export default withTranslation()(HomeMain);
