import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import Navbar from "./components/navbar";
// import Counters from "./components/counters";
import LandingPage from "./views/landingPage";
import Rentals from "./views/rent";
import Catering from "./views/catering";

import "./App.css";
import { withTranslation } from 'react-i18next';

class App extends Component {

  componentDidMount () {
    // document.body.style.backgroundColor = "lightgrey";
    // document.body.style.background = "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.5) 50%, rgba(255,255,255,1) 100%)";
    // document.body.style.msScrollSnapType = "y mandatory";
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route path="/" exact render={(state) => <LandingPage />} />
          <Route path="/rent:startDate?:endDate?"
            render={() => <Rentals />} />
          <Route path="/catering" exact render={(state) => <Catering />} />
        </Switch>
      </Router>
    );
  }
}

export default withTranslation()(App);
