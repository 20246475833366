import React, { Component } from "react";
import Header from "../components/header";
import Home from "../components/home";
// import References from "../components/references";
import Copyright from "../components/copyright";
import { Helmet } from 'react-helmet';
import { withTranslation } from "react-i18next";


class LandingPage extends Component {
  render() {
    const { t } = this.props;
    
    return (
      <div>
        <Helmet>
          <title>Servistor</title>
          <meta name="description" content={t("landing.main.cateringInfo")} />
        </Helmet>
        <Header />
        <Home />
        {/* <References /> */}
        <Copyright />
      </div>
    );
  }
}

export default withTranslation()(LandingPage);
